import { css } from '@emotion/core'
import { COLOR, Screen } from 'utils/mixins'

export const tagLineContainer = css`
	height: 100vh;
`

export const detailContainer = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;

	@media (max-width: ${Screen.xs}px) {
		padding-top: 0;
	}
`

export const dateTextStyled = css`
	font-family: Montserrat;
	color: ${COLOR.BLUE_9};
`

export const venueTextStyled = css`
	font-family: Kanit;
	color: ${COLOR.F_BLACK};
`

export const logoStyled = css`
	margin-top: 77px;

	@media (max-width: ${Screen.xs}px) {
		margin-top: 0px;
	}
`

export const buyTicketButtonStyled = css`
	margin-top: 30px;
`
