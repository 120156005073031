import React from 'react'

export default function RecruitIcon() {
	return (
		<svg
			fill="none"
			height="66"
			viewBox="0 0 80 66"
			width="80"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g fill="#ff6445">
				<path d="m39.6469 20.952c-.3315 1.0816-1.0775 2.4128-2.4865 4.0768-.9118 1.0816.1657 2.5792 1.4919 2.1632 2.7352-.9984 5.8019-2.4128 8.62-3.8272.8289.0832 1.7406.1664 2.6523.1664 9.0345 0 16.3283-5.2416 16.3283-11.7312 0-6.48956-7.2938-11.7311523-16.3283-11.7311523-9.0344 0-16.4112 5.2415923-16.4112 11.7311523 0 3.744 2.4037 6.9888 6.1335 9.152zm18.3175-11.64796c1.4091 0 2.5695 1.16476 2.5695 2.57916s-1.1604 2.5792-2.5695 2.5792c-1.409 0-2.5694-1.1648-2.5694-2.5792 0-1.4976 1.1604-2.57916 2.5694-2.57916zm-8.1227 0c1.4091 0 2.5695 1.16476 2.5695 2.57916s-1.1604 2.5792-2.5695 2.5792c-1.409 0-2.5694-1.1648-2.5694-2.5792.0829-1.4976 1.1604-2.57916 2.5694-2.57916zm-8.0398 0c1.4091 0 2.5694 1.16476 2.5694 2.57916s-1.1603 2.5792-2.5694 2.5792c-1.409 0-2.5694-1.1648-2.5694-2.5792 0-1.4976 1.1604-2.57916 2.5694-2.57916z" />
				<path d="m23.5673 35.5952c4.9896 0 9.0344-4.0602 9.0344-9.0688 0-5.0085-4.0448-9.0688-9.0344-9.0688s-9.0345 4.0603-9.0345 9.0688c0 5.0086 4.0449 9.0688 9.0345 9.0688z" />
				<path d="m39.3154 47.2433c-.9117-4.9088-5.2217-8.5696-10.3606-8.5696h-10.6921c-5.802 0-10.60927 4.7424-10.60927 10.6496v3.4112h-5.88483c-.58019 0-.994613.416-.994613.9984v2.1632c0 .5824.414423.9984.994613.9984h31.0818c.746-4.0768 3.1496-7.488 6.465-9.6512z" />
				<path d="m70.8116 52.6512c.6631 1.2481 1.1604 2.6625 1.492 4.1601h6.2164c.5802 0 .9946-.416.9946-.9984v-2.1632c0-.5824-.4144-.9985-.9946-.9985z" />
				<path d="m57.8817 48.9905h-10.6093c-5.8019 0-10.6093 4.7424-10.6093 10.6495v4.4928c0 .5824.4145.9984.9947.9984h29.8385c.5802 0 .9947-.416.9947-.9984v-4.4928c0-5.9071-4.7245-10.6495-10.6093-10.6495z" />
				<path d="m52.577 45.912c4.9896 0 9.0345-4.0602 9.0345-9.0688s-4.0449-9.0688-9.0345-9.0688-9.0345 4.0602-9.0345 9.0688 4.0449 9.0688 9.0345 9.0688z" />
			</g>
		</svg>
	)
}
