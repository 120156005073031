import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Venue from 'pageComponents/index/venue'
import TagLine from 'pageComponents/index/tagLine'
import About from 'pageComponents/index/about'
import Sponsors from 'pageComponents/index/sponsors'
import Agenda from 'pageComponents/index/agenda'

const IndexPage = () => (
	<Layout>
		<SEO title="Brand Inside Forum 2020: New Workforce" />
		<TagLine />
		<About />
		<Sponsors />
		<Agenda />
		<Venue />
	</Layout>
)

export default IndexPage
