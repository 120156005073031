import React from 'react'

export default function hrIcon() {
	return (
		<svg
			fill="none"
			height="63"
			viewBox="0 0 63 63"
			width="63"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m62.2326 56.6699-14.8632-14.8727c3.2122-4.3556 5.1297-9.7216 5.1297-15.5483 0-14.4955-11.7478-26.2489-26.2495-26.2489-14.4962 0-26.2496 11.7534-26.2496 26.2497 0 14.4994 11.7534 26.2496 26.2496 26.2496 5.8314 0 11.195-1.9199 15.5506-5.1345l14.8703 14.868c1.0206 1.0229 2.6917 1.0229 3.7123 0l1.8514-1.8538c1.0213-1.0174 1.0213-2.6909-.0016-3.7091zm-35.983-46.1702c3.6256 0 6.5622 2.9397 6.5622 6.5646 0 3.6248-2.9366 6.5598-6.5622 6.5598-3.6249.0008-6.5622-2.935-6.5622-6.5598 0-3.6249 2.9373-6.5646 6.5622-6.5646zm15.7499 29.6107c-3.8454 4.3714-9.4681 7.1418-15.7499 7.1418-6.2795 0-11.9022-2.7712-15.7484-7.1442v-3.3586c0-4.3321 3.543-7.8727 7.8734-7.8727h15.7499c4.3352 0 7.875 3.5406 7.875 7.8727z"
				fill="#ff6445"
			/>
		</svg>
	)
}
