import { css } from '@emotion/core'
import { COLOR, Screen } from 'utils/mixins'

export const agendaContainerStyled = css`
	padding-top: 70px;
	padding-bottom: 60px;
`

export const tableContainer = css`
	margin-top: 38px;
	word-break: break-word;

	@media (max-width: ${Screen.xs}px) {
		padding-left: 0;
		padding-right: 0;
	}
`

export const tableHeadStyled = css`
	background-color: ${COLOR.BLUE_9};
`

export const luncheonStyled = css`
	background-color: ${COLOR.ORANGE};
	color: ${COLOR.WHITE};
`

export const agendaEngTextStyled = css`
	font-family: Montserrat;
	min-width: 100px;
`

export const agendaThaTextStyled = css`
	font-family: Kanit;
`

export const tableStyled = css`
	border: ${COLOR.BLUE_9} 2px solid;
	width: 100%;
`

export const tableRowStyled = css`
	border-bottom: ${COLOR.BLUE_9} 2px solid !important;
`

export const tableHeadRowStyled = css`
	border-bottom: ${COLOR.BLUE_9} 3px solid !important;
`

export const tableLuncheonRowStyled = css`
	border-left: ${COLOR.BLUE_9} 2px solid !important;
	border-right: ${COLOR.BLUE_9} 2px solid !important;
	border-bottom: ${COLOR.ORANGE} 2px solid !important;
`
