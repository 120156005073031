import React from 'react'

export default function EmployeeIcon() {
	return (
		<svg
			fill="none"
			height="76"
			viewBox="0 0 73 76"
			width="73"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g fill="#ff6445">
				<path d="m20.6443 26.08c-1.4379-.96-3.1955-1.44-5.0329-1.44h-5.99171c-5.19277 0-9.42688 4.24-9.42688 9.44v8.96c0 2.32 1.438 4.4001 3.67489 5.1201l1.11844.4 1.19833 20.64c.07989 1.44 1.27822 2.64 2.79611 2.64h7.34982c1.438 0 2.7162-1.12 2.7961-2.64l1.1184-20c-1.1184-1.6001-1.7575-3.6001-1.7575-5.6001v-10.08c.0798-2.72.7988-5.28 2.1569-7.44z" />
				<path d="m12.6555 22.08c4.9416 0 8.9476-4.0116 8.9476-8.9601 0-4.94845-4.006-8.95999-8.9476-8.95999-4.94159 0-8.94755 4.01154-8.94755 8.95999 0 4.9485 4.00596 8.9601 8.94755 8.9601z" />
				<path d="m72.4125 34c0-5.2001-4.2341-9.4401-9.4269-9.4401h-5.9917c-1.8375 0-3.595.56-5.033 1.44 1.3581 2.16 2.0771 4.72 2.0771 7.44v10.0801c0 2.08-.6391 4-1.7576 5.6l1.1185 20c.0799 1.44 1.2782 2.64 2.7961 2.64h7.3498c1.438 0 2.7162-1.12 2.7961-2.64l1.1983-20.64 1.1185-.4c2.2369-.72 3.6749-2.8 3.6749-5.12z" />
				<path d="m59.9498 22.08c4.9416 0 8.9475-4.0116 8.9475-8.9601 0-4.94845-4.0059-8.95999-8.9475-8.95999s-8.9476 4.01154-8.9476 8.95999c0 4.9485 4.006 8.9601 8.9476 8.9601z" />
				<path d="m39.658 22.96h-6.7107c-5.8319 0-10.5453 4.72-10.6252 10.56v10.08c0 2.64 1.6777 4.96 4.1542 5.76l1.2782.4 1.3581 23.28c.0799 1.68 1.438 2.96 3.1157 2.96h8.2286c1.6776 0 3.0358-1.28 3.1156-2.96l1.3582-23.28 1.2782-.4c2.4765-.88 4.1542-3.2 4.1542-5.76v-10.08c-.1598-5.84-4.8732-10.56-10.7051-10.56z" />
				<path d="m36.3026 20c5.5152 0 9.9862-4.4772 9.9862-10 0-5.52285-4.471-10-9.9862-10-5.5151 0-9.9861 4.47715-9.9861 10 0 5.5228 4.471 10 9.9861 10z" />
			</g>
		</svg>
	)
}
