import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { sponsorsTitleTextStyled, sponsorsContainer } from './styled'

export default function Sponsors() {
	const { scb, ais, dtac, seac, sea } = useStaticQuery(graphql`
		query {
			scb: file(relativePath: { eq: "icons/sponsors/scb-icon.png" }) {
				childImageSharp {
					fixed(width: 200, height: 90) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
			ais: file(relativePath: { eq: "icons/sponsors/ais-icon.png" }) {
				childImageSharp {
					fixed(width: 160, height: 58) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
			dtac: file(relativePath: { eq: "icons/sponsors/dtac-icon.png" }) {
				childImageSharp {
					fixed(width: 153, height: 58) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
			seac: file(relativePath: { eq: "icons/sponsors/seac-icon.png" }) {
				childImageSharp {
					fixed(width: 82, height: 36) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
			sea: file(relativePath: { eq: "icons/sponsors/sea-icon.png" }) {
				childImageSharp {
					fixed(width: 153, height: 71) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
		}
	`)
	return (
		<div className="container" css={sponsorsContainer}>
			<div
				className="is-size-3 has-text-weight-bold has-text-centered"
				css={sponsorsTitleTextStyled}
			>
				<span>Sponsors</span>
			</div>
			<div className="columns is-centered is-vcentered">
				<div className="column has-text-centered is-half">
					<Img fixed={scb.childImageSharp.fixed} />
				</div>
			</div>
			<div className="columns is-centered is-vcentered">
				<div className="column has-text-centered is-one-quarter">
					<Img fixed={ais.childImageSharp.fixed} />
				</div>
				<div className="column has-text-centered is-one-quarter">
					<Img fixed={dtac.childImageSharp.fixed} />
				</div>
			</div>
			<div className="columns is-centered is-vcentered">
				<div className="column has-text-centered is-one-quarter">
					<Img fixed={seac.childImageSharp.fixed} />
				</div>
				<div className="column has-text-centered is-one-quarter">
					<Img fixed={sea.childImageSharp.fixed} />
				</div>
			</div>
		</div>
	)
}
