import React from 'react'

export default function ComorgIcon() {
	return (
		<svg
			fill="none"
			height="69"
			viewBox="0 0 69 69"
			width="69"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				clipRule="evenodd"
				d="m27.6442 15.3646c-4.1845 1.4987-7.1998 5.5074-7.1998 10.1858v3.8882c0 1.9837 1.6248 3.6054 3.6103 3.6054h10.3364 10.3357c1.9855 0 3.6102-1.6217 3.6102-3.6054v-3.8882c0-4.6784-3.0153-8.6871-7.1998-10.1858 1.3303-1.5511 2.1345-3.5658 2.1345-5.76699 0-4.8977-3.9762-8.868484-8.8806-8.868484-4.9043 0-8.8813 3.970784-8.8813 8.868484 0 2.20119.8042 4.21589 2.1344 5.76699zm-22.55427 37.676c-2.85168 1.1626-4.862147 3.9605-4.862147 7.2271 0 4.3095 3.499157 7.804 7.815427 7.804 4.31629 0 7.81549-3.4945 7.81549-7.804 0-3.2752-2.0209-6.0792-4.8846-7.2374v-6.9235h20.221v7.0362c-2.7226 1.2201-4.6194 3.951-4.6194 7.1247 0 4.3095 3.4992 7.804 7.8154 7.804 4.3155 0 7.8146-3.4945 7.8146-7.804 0-3.3672-2.1344-6.2365-5.1265-7.3311v-6.8298h20.7273v6.9244c-2.8629 1.1582-4.8837 3.9613-4.8837 7.2365 0 4.3095 3.4991 7.804 7.8154 7.804 4.3154 0 7.8154-3.4945 7.8154-7.804 0-3.2675-2.0104-6.0646-4.863-7.2279v-9.8711c0-1.6217-1.3182-2.9381-2.9421-2.9381h-52.71648c-1.62387 0-2.94209 1.3164-2.94209 2.9381z"
				fill="#ff6445"
				fillRule="evenodd"
			/>
		</svg>
	)
}
