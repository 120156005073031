import React from 'react'
import {
	agendaContainerStyled,
	agendaEngTextStyled,
	agendaThaTextStyled,
	luncheonStyled,
	tableContainer,
	tableHeadRowStyled,
	tableHeadStyled,
	tableLuncheonRowStyled,
	tableRowStyled,
	tableStyled,
} from './styled'

export default function Agenda() {
	return (
		<div id="agenda" className="container" css={agendaContainerStyled}>
			<div className="has-text-centered">
				<h2
					className="is-size-3 has-text-weight-bold is-uppercase has-text-black"
					css={agendaEngTextStyled}
				>
					agenda
				</h2>
			</div>
			<div css={tableContainer}>
				<table className="table" css={tableStyled}>
					<thead>
						<tr css={[tableHeadStyled, tableHeadRowStyled]}>
							<th
								className="has-text-centered has-text-white has-text-weight-bold is-uppercase"
								css={agendaEngTextStyled}
							>
								time
							</th>
							<th
								className="has-text-centered has-text-white has-text-weight-bold is-uppercase"
								css={agendaEngTextStyled}
							>
								topic
							</th>
						</tr>
					</thead>
					<tbody>
						<tr css={tableRowStyled}>
							<td className="has-text-centered">
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									9:00 - 10:00
								</span>
							</td>
							<td>
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									Registration
								</span>
							</td>
						</tr>
						<tr css={tableRowStyled}>
							<td className="has-text-centered">
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									10:15 - 11:00
								</span>
							</td>
							<td>
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									Keynote: 21<sup>st</sup> Century Leadership
								</span>
								<br />
								<span css={agendaThaTextStyled}>
									<span className="has-text-weight-bold">
										ธนา เธียรอัจฉริยะ
									</span>
									, Chief Marketing Officer, SCB
								</span>
							</td>
						</tr>
						<tr css={tableRowStyled}>
							<td className="has-text-centered">
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									11:10 - 11:40
								</span>
							</td>
							<td>
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									Keynote: Modernize Your Workforce
								</span>
								<br />
								<span css={agendaThaTextStyled}>
									<span className="has-text-weight-bold">
										กานติมา เลอเลิศยุติธรรม
									</span>
									, Group Chief Human Resources Officer, AIS
								</span>
							</td>
						</tr>
						<tr css={tableLuncheonRowStyled}>
							<td className="has-text-centered">
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									11:50 - 12.30
								</span>
							</td>
							<td>
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									Panel: Skills for 21<sup>st</sup> Century Workforce
								</span>
								<br />
								<span css={agendaThaTextStyled}>
									Moderator : ธงชัย ชลศิริพงษ์, Content Editor, Brand Inside
								</span>
								<ul css={agendaThaTextStyled}>
									<li>
										<span className="has-text-weight-bold">
											อริญญา เถลิงศรี
										</span>
										, Chief Capability Officer & Managing Director, SEAC
									</li>
									<li>
										<span className="has-text-weight-bold">
											ภคพรรค์ วัลลศิริ
										</span>
										, Country Manager, Gallup
									</li>
								</ul>
							</td>
						</tr>
						<tr css={[luncheonStyled, tableLuncheonRowStyled]}>
							<td className="has-text-centered">
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									12.30 - 13.30
								</span>
							</td>
							<td>
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									Lunch Break
								</span>
							</td>
						</tr>
						<tr css={tableRowStyled}>
							<td className="has-text-centered">
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									13:30 - 14:00
								</span>
							</td>
							<td>
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									Keynote: dtac Transformation Organization
								</span>
								<br />
								<span css={agendaThaTextStyled}>
									<span
										className="has-text-weight-bold"
										css={agendaThaTextStyled}
									>
										ชารัด เมห์โรทรา
									</span>
									, Chief Executive Officer, dtac
								</span>
							</td>
						</tr>
						<tr css={tableRowStyled}>
							<td className="has-text-centered">
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									14:10 - 14:40
								</span>
							</td>
							<td>
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									Keynote: Transformation Management for Sustainability
								</span>
								<br />
								<span css={agendaThaTextStyled}>
									<span
										className="has-text-weight-bold"
										css={agendaThaTextStyled}
									>
										ดร.กำพล ฤทัยวณิช
									</span>
									, Senior Executive Vice President - Human Resources Group,
									Mitr Phol Group
								</span>
							</td>
						</tr>
						<tr css={tableRowStyled}>
							<td className="has-text-centered">
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									14:50 - 15:30
								</span>
							</td>
							<td>
								<span
									className="has-text-weight-bold"
									css={agendaEngTextStyled}
								>
									Panel: How to Deal with Cross-gen Workforce
								</span>
								<br />
								<span css={agendaThaTextStyled}>
									Moderator : อานนทวงศ์ มฤคพิทักษ์, Head of People, LINE MAN
									Wongnai
								</span>
								<br />
								<ul css={agendaThaTextStyled}>
									<li>
										<span className="has-text-weight-bold">
											ดร.ศรุต วานิชพันธุ์
										</span>
										, Director of Sea (Thailand)
									</li>
									<li>
										<span className="has-text-weight-bold">
											อภิรัตน์ หวานชะเอม
										</span>
										, Chief Digital Officer, SCG CBM
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}
