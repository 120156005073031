import { css } from '@emotion/core'
import { COLOR, Screen } from 'utils/mixins'

export const aboutContainer = css`
	background-color: ${COLOR.YELLOW};
	padding: 70px 20px 60px 20px;
`

export const amountStyled = css`
	color: ${COLOR.ORANGE};
`

export const amountWithTextStyled = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`

export const iconWithTextStyled = css`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const amountContainerStyled = css`
	margin-top: 32px;
	margin-bottom: 32px;
`

export const amountTextStyled = css`
	font-size: 100px;

	@media (max-width: ${Screen.xs}px) {
		font-size: 70px;
	}
`

export const aboutTitleStyled = css`
	font-family: Montserrat;
	margin-bottom: 54px;
`

export const aboutDetailTextStyled = css`
	font-family: Kanit;
	font-size: 20px;
	word-break: break-word;
`

export const passionContainer = css`
	margin-top: 30px;
	color: ${COLOR.F_BLACK};
`
