import React from 'react'
import {
	venueContainer,
	venueDetailStyled,
	mrtContainerStyled,
	mrtTextStyled,
	venueTextStyled,
} from './styled'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export default function Venue() {
	const data = useStaticQuery(graphql`
		query {
			venue: file(relativePath: { eq: "images/venue/venue.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			mrtIcon: file(relativePath: { eq: "icons/mrt.png" }) {
				childImageSharp {
					fixed(width: 54, height: 47) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
		}
	`)

	return (
		<div css={venueContainer}>
			<Img fluid={data.venue.childImageSharp.fluid} />
			<div className="has-text-white" css={venueDetailStyled}>
				<span className="is-uppercase is-size-3 has-text-weight-bold">
					venue
				</span>
				<p className="is-size-5" css={venueTextStyled}>
					สามย่านมิตรทาวน์ฮอลล ชั้น 5 <br /> สามย่านมิตรทาวน์ กรุงเทพฯ
				</p>
				<p className="is-size-5">
					Samyan Mitrtown Hall, 5th floor
					<br />
					Samyan Mitrtown Bangkok.
				</p>
				<div css={mrtContainerStyled}>
					<Img fixed={data.mrtIcon.childImageSharp.fixed} />
					<span css={mrtTextStyled} className="is-uppercase is-size-5">
						MRT SAMYAN
					</span>
				</div>
				<a
					role="button"
					href="https://goo.gl/maps/23uoKC58QUeTojeH6"
					className="is-uppercase button is-warning is-outlined has-text-weight-bold is-medium"
					target="_blank"
					rel="noopener noreferrer"
				>
					get direction
				</a>
			</div>
		</div>
	)
}
