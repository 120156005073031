import { css } from '@emotion/core'
import { Screen, COLOR } from 'utils/mixins'

export const venueContainer = css`
	background-color: ${COLOR.DARK_GREY_4};

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;

	@media (max-width: ${Screen.xs}px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, 1fr);
	}
`

export const venueDetailStyled = css`
	padding: 71px;

	@media (max-width: ${Screen.xs}px) {
		padding: 32px;
	}
`

export const mrtContainerStyled = css`
	margin-top: 8px;
	margin-bottom: 23px;

	display: flex;
	flex-direction: row;
	align-items: center;
`

export const mrtTextStyled = css`
	margin: 6px;
`

export const venueTextStyled = css`
	font-family: 'Prompt';
`
