import React from 'react'
import {
	aboutContainer,
	amountStyled,
	amountWithTextStyled,
	amountContainerStyled,
	amountTextStyled,
	aboutTitleStyled,
	aboutDetailTextStyled,
	iconWithTextStyled,
	passionContainer,
} from './styled'
import HRIcon from 'assets/icons/about/hrIcon'
import EmployeeIcon from 'assets/icons/about/EmployeeIcon'
import ComorgIcon from 'assets/icons/about/ComorgIcon'
import RecruitIcon from 'assets/icons/about/RecruitIcon'

export default function About() {
	return (
		<div id="about" css={aboutContainer}>
			<div className="container has-text-black">
				<div className="has-text-centered" css={aboutTitleStyled}>
					<span className="is-size-3 is-uppercase has-text-weight-bold">
						about
					</span>
				</div>
				<p css={aboutDetailTextStyled}>
					กลับมาอีกครั้งกับงานสัมนาธุรกิจ Brand Inside Forum ประจำปี 2020
					กับหัวข้อ <span className="has-text-weight-bold">New Workforce</span>{' '}
					เพราะ <span className="has-text-weight-bold">‘คน’</span>{' '}
					เป็นทรัพยากรสำคัญ ในการขับเคลื่อนธุรกิจ แต่คนทำงานจะต้องมีทักษะ
					และวิธีการทำงานอย่างไร ในยุคสมัยแห่งการเปลี่ยนผ่านไปสู่โลกอนาคต
				</p>
				<br />
				<p css={aboutDetailTextStyled}>
					ตอนนี้ทุกอุตสาหกรรม องค์กรทั่วโลก รวมถึงประเทศไทย กำลังเผชิญหน้ากับ
					Transformation และ Disruption งานสัมนาเรื่อง{' '}
					<span className="has-text-weight-bold">New Workforce </span>{' '}
					จึงเป็นสิ่งที่ตอบโจทย์ ‘คนทำงาน’
					ที่อยากพัฒนาทักษะเพื่อให้สามารถอยู่รอด
					และเติบโตได้ท่ามกลางการแข่งขันที่รุนแรง
					อีกทั้งหลายองค์กรเริ่มมีพนักงานเป็นเด็กรุ่นใหม่จำนวนมากขึ้น
					แล้วการทำงานท่ามกลางความแตกต่างทั้ง อายุ ความคิดและการใช้ชีวิต คน Gen
					X จะทำงานรวมกับคน Gen Y และคน Gen Z ได้อย่างไร
				</p>
				<br />
				<p css={aboutDetailTextStyled}>
					<span className="has-text-weight-bold">
						Brand Inside Forum 2020: New Workforce
					</span>{' '}
					อยากชวนคนทำงานทุกคนมาร่วมฟัง และเสนอทางออกและเป็นทางเลือกให้กับองค์กร
					และคนทำงาน ก้าวผ่านยุคแห่งการเปลี่ยนผ่านนี้ไปด้วยกัน
				</p>
			</div>
			<div className="container" css={amountContainerStyled}>
				<div className="columns has-text-weight-bold" css={amountStyled}>
					<div className="column" css={amountWithTextStyled}>
						<span className="is-uppercase has-text-centered is-size-5">
							attendees
						</span>
						<span css={amountTextStyled}>550+</span>
					</div>
					<div className="column" css={amountWithTextStyled}>
						<span className="is-uppercase has-text-centered is-size-5">
							speakers
						</span>
						<span css={amountTextStyled}>08</span>
					</div>
					<div className="column" css={amountWithTextStyled}>
						<span className="is-uppercase has-text-centered  is-size-5">
							Session & Panel <br /> discussions
						</span>
						<span css={amountTextStyled}>06</span>
					</div>
				</div>
			</div>
			<div className="has-text-centered">
				<span className="is-uppercase is-size-4 has-text-weight-bold has-text-black">
					People who are passion about
				</span>
			</div>
			<div className="container" css={passionContainer}>
				<div className="columns">
					<div className="column" css={iconWithTextStyled}>
						<HRIcon />
						<span className="has-text-weight-bold">HR</span>
					</div>
					<div className="column" css={iconWithTextStyled}>
						<EmployeeIcon />
						<span className="has-text-weight-bold has-text-centered">
							Employee
							<br /> Gen X, Y, Z
						</span>
					</div>
					<div className="column" css={iconWithTextStyled}>
						<ComorgIcon />
						<span className="has-text-weight-bold has-text-centered">
							Company /<br /> Organization
						</span>
					</div>
					<div className="column" css={iconWithTextStyled}>
						<RecruitIcon />
						<span className="has-text-weight-bold has-text-centered">
							Recruitment
							<br />
							company
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}
