import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import React from 'react'
import {
	buyTicketButtonStyled,
	dateTextStyled,
	detailContainer,
	logoStyled,
	tagLineContainer,
	venueTextStyled,
} from './styled'

const TagLine = () => {
	const data = useStaticQuery(graphql`
		query {
			bg: file(relativePath: { eq: "images/tagLine/tagline-bg.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			logo: file(relativePath: { eq: "images/tagLine/theme-logo.png" }) {
				childImageSharp {
					fixed(width: 302, height: 267) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
		}
	`)
	return (
		<BackgroundImage
			fluid={data.bg.childImageSharp.fluid}
			css={tagLineContainer}
		>
			<div css={detailContainer}>
				<Img fixed={data.logo.childImageSharp.fixed} css={logoStyled} />
				<span className="is-size-3 has-text-weight-bold" css={dateTextStyled}>
					02.11.2020
				</span>
				<span className="has-text-centered is-size-5" css={venueTextStyled}>
					สามย่านมิตรทาวน์ฮอลล์ ชั้น 5<br />
					สามย่านมิตรทาวน์ กรุงเทพฯ เวลา 9.00 - 15.30 น.
				</span>
				<a
					href="https://www.eventpop.me/e/8296"
					className="button is-uppercase is-warning has-text-weight-bold is-size-5"
					target="_blank"
					rel="noopener noreferrer"
					css={buyTicketButtonStyled}
				>
					buy ticket
				</a>
			</div>
		</BackgroundImage>
	)
}

export default TagLine
