import { css } from '@emotion/core'
import { COLOR } from 'utils/mixins'

export const sponsorsContainer = css`
	padding: 66px 16px;
`

export const sponsorsTitleTextStyled = css`
	font-family: Montserrat;
	color: ${COLOR.F_BLACK};
`
